:root {
	--black-color: #111111;
	--white-color: #ffffff;
	--red-color: #fe2c55;
	--grey-color: #5b6b79;
	--placeholder-color: #828282;
	--item-bg-primary: #f2f2f2;
	--item-bg-secondary: #ecebeb;
	--item-bg-primary-hover: #e4e4e4;
	--item-bg-secondary-hover: #dddddd;
	--item-bg-primary-active: #d5d5d5;
	--item-bg-secondary-active: #c6c6c6;
	--radius-item: 12px;
}
